import React from 'react';
import { bool } from 'prop-types';
import Loader from 'ui-kit/atoms/Loader';

import cx from './PageLoader.sss';

const PageLoader = ({ transparentBackground }) => (
    <div className={cx('root', { transparentBackground })}>
        <Loader size="large" theme="green" />
    </div>
);

PageLoader.propTypes = {
    transparentBackground: bool,
};

export default PageLoader;
