import React from 'react';
import { ContentLoaderBase } from 'shared';

import { Depth } from '@s7/ui-kit';

import cx from './Cards.sss';

const CardLoader = () => {
    return (
        <div className={cx('card-loader')}>
            <Depth depth="z50">
                <div className={cx('mobile')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={224}
                        speed={1.5}
                        title={null}
                        uniqueKey="FlightSkeletonLoaderMobile"
                        width={328}
                    >
                        <rect height="20" rx="8" width="160" x="20" y="20" />
                        <rect height="28" rx="8" width="288" x="20" y="64" />
                        <rect height="24" rx="8" width="288" x="20" y="96" />
                        <rect height="40" rx="8" width="288" x="20" y="164" />

                        <rect height="20" rx="8" width="20" x="288" y="20" />
                    </ContentLoaderBase>
                </div>
                <div className={cx('desktop')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={172}
                        speed={1.5}
                        title={null}
                        uniqueKey="FlightSkeletonLoader"
                        width={720}
                    >
                        <rect height="20" rx="8" width="200" x="24" y="24" />
                        <rect height="32" rx="8" width="200" x="24" y="84" />
                        <rect height="24" rx="8" width="200" x="24" y="124" />
                        <rect height="20" rx="8" width="20" x="676" y="24" />
                    </ContentLoaderBase>
                </div>
            </Depth>
        </div>
    );
};

export default CardLoader;
