import React, { PureComponent } from 'react';
import { ContentLoaderBase } from 'shared';

import cx from './SkeletonProcessingConfirmationCode.sss';

export class SkeletonProcessingConfirmationCode extends PureComponent {
    renderMobile = () => (
        // TODO make responsive mobile loader
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={489}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonProcessingConfirmationCodeMobile"
            width={360}
        >
            <rect height="80" rx="0" ry="0" width="250" x="16" y="40" />
            <rect height="50" rx="0" ry="0" width="260" x="16" y="145" />
            <rect height="50" rx="0" ry="0" width="260" x="16" y="210" />
            <rect height="55" rx="0" ry="0" width="290" x="16" y="308" />
            <rect height="60" rx="0" ry="0" width="290" x="16" y="386" />
        </ContentLoaderBase>
    );

    renderLarge = () => (
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={380}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonProcessingConfirmationCode"
            width={360}
        >
            <rect height="24" rx="0" ry="0" width="296" x="32" y="40" />

            <rect height="32" rx="0" ry="0" width="296" x="32" y="80" />

            <rect height="60" rx="0" ry="0" width="296" x="32" y="136" />

            <rect height="56" rx="8" ry="8" width="296" x="32" y="220" />

            <rect height="48" rx="0" ry="0" width="296" x="32" y="300" />
        </ContentLoaderBase>
    );

    render() {
        return (
            <div className={cx('root')}>
                <div className={cx('desktop')}>{this.renderLarge()}</div>
                <div className={cx('mobile')}>{this.renderMobile()}</div>
            </div>
        );
    }
}
