import React from 'react';
import { ContentLoaderBase } from 'shared';

import cx from './SkeletonLoaderHeader.sss';

import Hidden from '../Hidden';

const SkeletonLoaderHeader = () => (
    <div className={cx('SkeletonLoaderHeader')}>
        <Hidden on="mobile">
            <ContentLoaderBase
                backgroundColor="#FFFFFF"
                className={cx('main')}
                foregroundColor="#FFFFFF"
                height={80}
                speed={1.5}
                title={null}
                uniqueKey="SkeletonLoaderHeader"
                width={768}
            >
                <rect height="80" rx="0" ry="0" width="1000" x="0" y="0" />
            </ContentLoaderBase>
        </Hidden>
        <Hidden on="desktop">
            <ContentLoaderBase
                backgroundColor="#FFFFFF"
                foregroundColor="#FFFFFF"
                height={52}
                speed={1.5}
                title={null}
                uniqueKey="SkeletonLoaderHeaderMobile"
                width={768}
            >
                <rect height="14" rx="0" ry="0" width="1" x="0" y="0" />
            </ContentLoaderBase>
        </Hidden>
    </div>
);

export default SkeletonLoaderHeader;
