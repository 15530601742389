import React, { PureComponent } from 'react';
import { bool } from 'prop-types';
import { ContentLoaderBase } from 'shared';
import Loader from 'ui-kit/atoms/Loader';

import cx from './SkeletonConfirmRecovery.sss';

export class SkeletonConfirmRecovery extends PureComponent {
    static propTypes = {
        isPasswordSet: bool,
    };

    renderMobile = () => (
        // TODO make responsive mobile loader
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={489}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonConfirmRecoveryMobile"
            width={360}
        >
            <rect height="80" rx="0" ry="0" width="250" x="16" y="40" />
            <rect height="92" rx="0" ry="0" width="276" x="16" y="147" />

            <rect height="60" rx="0" ry="0" width="285" x="16" y="280" />
            <rect height="60" rx="0" ry="0" width="285" x="16" y="362" />

            <rect height="60" rx="8" ry="8" width="290" x="16" y="442" />
        </ContentLoaderBase>
    );

    renderLarge = () => (
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={572}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonConfirmRecovery"
            width={360}
        >
            <rect height="24" rx="0" ry="0" width="296" x="32" y="32" />

            <rect height="96" rx="0" ry="0" width="296" x="32" y="72" />

            <rect height="120" rx="0" ry="0" width="296" x="32" y="188" />

            <rect height="56" rx="4" ry="4" width="296" x="32" y="332" />
            <rect height="56" rx="4" ry="4" width="296" x="32" y="412" />

            <rect height="48" rx="4" ry="4" width="296" x="32" y="492" />
        </ContentLoaderBase>
    );

    render() {
        const { isPasswordSet } = this.props;

        return (
            <div
                className={cx('root', {
                    center: isPasswordSet,
                })}
            >
                {isPasswordSet ? (
                    <Loader size="large" />
                ) : (
                    <>
                        <div className={cx('desktop')}>
                            {this.renderLarge()}
                        </div>
                        <div className={cx('mobile')}>
                            {this.renderMobile()}
                        </div>
                    </>
                )}
            </div>
        );
    }
}
