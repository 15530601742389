import React from 'react';
import Loader from 'ui-kit/atoms/Loader';

import cx from './ConfirmContact.sss';

const ConfirmContactSSR = () => {
    return (
        <div className={cx('loader')}>
            <Loader />
        </div>
    );
};

export default ConfirmContactSSR;
