export function skipEvent(event, ignorePaths = []) {
    const { frames } = event.exception?.values?.[0]?.stacktrace || {};

    if (frames?.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = frames?.length - 1; i >= 0; i--) {
            const frame = frames[i];

            if (ignorePaths.some(path => frame.abs_path?.includes(path))) {
                return true;
            }
        }
    }

    return false;
}
