import React from 'react';
import ConfirmContactSSR from 'components/ConfirmContact/ConfirmContactSSR';
import PageLoader from 'components/PageLoader';
import TripLoader from 'components/Trips/SkeletonLoaders/TripLoader';
import TripsLoader from 'components/Trips/SkeletonLoaders/TripsLoader';
import { useAppContext } from 'contexts';
import { useRouter } from 'next/router';
import { bool, object } from 'prop-types';
import {
    SkeletonLoaderProfileHeader,
    SkeletonLoaderProfileMiles,
    SkeletonLoaderProfileView,
} from 'ui-kit/atoms/SkeletonLoader';
import SkeletonLoaderAuthorization from 'ui-kit/atoms/SkeletonLoaderAuthorization';
import SkeletonLoaderBreadcrumbs from 'ui-kit/atoms/SkeletonLoaderBreadcrumbs';
import SkeletonLoaderHeader from 'ui-kit/atoms/SkeletonLoaderHeader';
import {
    SkeletonConfirmRecovery,
    SkeletonProcessingConfirmationCode,
} from 'ui-kit/atoms/SkeletonLoaderPasswordRecovery';
import SkeletonLoaderRegistration, {
    SkeletonLoaderRegistrationFull,
} from 'ui-kit/atoms/SkeletonLoaderRegistration';

const AppSSR = ({ Unlogged }) => {
    const { inApp } = useAppContext();
    const router = useRouter();

    const { route, asPath, push } = router;

    if (
        typeof window !== 'undefined' &&
        Unlogged &&
        route.indexOf('/login') < 0 &&
        window.location.href.indexOf('/login') < 0
    ) {
        const referrer = encodeURIComponent(asPath);

        if (decodeURIComponent(referrer) === '/') {
            push('/login');
        } else {
            push(`/login?redirect=${referrer}`);
        }
    }

    const isProfile = route === '/';
    const isMiles = route.indexOf('/miles') >= 0;
    const isTravels = route.indexOf('/travels') >= 0;
    const isTravel = asPath.indexOf('/travels/') >= 0;
    const isStatistics = route.indexOf('/statistics') >= 0;
    const isSettings = route.indexOf('/settings') >= 0;
    const isOffers = route.indexOf('/offers') >= 0;
    const isPublic = route.indexOf('/public') >= 0;
    const isSubscriptions = route.indexOf('/subscriptions') >= 0;
    const isAuthPages =
        isProfile ||
        isMiles ||
        isTravels ||
        isStatistics ||
        isOffers ||
        isSettings ||
        isSubscriptions;
    const isB2b = route.indexOf('/b2b') >= 0;
    const isLoginPage = route.indexOf('/login') >= 0 || isB2b;
    const isRegistration = route.indexOf('/registration') >= 0;
    const isRegistrationFull = route.indexOf('/registration/') >= 0;
    const isConfirmRecovery = route.indexOf('/confirm-recovery') >= 0;
    const isProcessingConfirmationCode =
        route.indexOf('/processingConfirmation') >= 0;
    const isPasswordSet = asPath.indexOf('passwordSet=true') >= 0;
    const isProcessingConfirmationContact =
        route.indexOf('/confirm-contact') >= 0;

    return (
        <div>
            {!inApp && (
                <>
                    <SkeletonLoaderHeader />
                    <SkeletonLoaderBreadcrumbs />
                </>
            )}

            {(isLoginPage || Unlogged) && !isB2b && (
                <SkeletonLoaderAuthorization />
            )}

            {isRegistration && !isRegistrationFull && (
                <SkeletonLoaderRegistration />
            )}
            {isRegistrationFull && <SkeletonLoaderRegistrationFull />}

            {isConfirmRecovery && (
                <SkeletonConfirmRecovery isPasswordSet={isPasswordSet} />
            )}
            {isProcessingConfirmationCode && (
                <SkeletonProcessingConfirmationCode />
            )}
            {isProcessingConfirmationContact && <ConfirmContactSSR />}

            {!Unlogged && isAuthPages && <SkeletonLoaderProfileHeader />}
            {!Unlogged && isProfile && <SkeletonLoaderProfileView />}
            {!Unlogged && isMiles && <SkeletonLoaderProfileMiles />}
            {!Unlogged && isTravels && !isTravel && <TripsLoader />}
            {!Unlogged && isTravel && <TripLoader />}
            {!Unlogged && isSubscriptions && <PageLoader />}

            {isPublic && <SkeletonLoaderProfileHeader isPublic />}
            {isPublic && <PageLoader />}
        </div>
    );
};

AppSSR.propTypes = {
    pageProps: object,
    Unlogged: bool,
};

export default AppSSR;
