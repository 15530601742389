import React from 'react';

import cx from './TripsLoaders.sss';

import CardLoader from '../Cards/CardSkeletonLoader';

const TripsLoader = () => {
    return (
        <div className={cx('root')}>
            <div className={cx('skeleton')}>
                <>
                    <div className={cx('switcher')} />
                    <CardLoader />
                    <CardLoader />
                    <CardLoader />
                </>
            </div>
        </div>
    );
};

export default TripsLoader;
