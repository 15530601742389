import React from 'react';
import { ContentLoaderBase } from 'shared';

import { Depth } from '@s7/ui-kit';

import cx from './TripsLoaders.sss';

const TripLoader = () => {
    const cardLoader = (
        <div className={cx('card')}>
            <Depth depth="z50">
                <div className={cx('mobile')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={148}
                        speed={1.5}
                        title={null}
                        uniqueKey="TripCardLoader"
                        width={344}
                    >
                        <rect height="28" rx="8" width="156" x="16" y="16" />
                        <rect height="20" rx="8" width="100" x="20" y="48" />
                        <rect height="48" rx="8" width="312" x="20" y="84" />
                        <rect height="40" rx="8" width="40" x="288" y="16" />
                    </ContentLoaderBase>
                </div>
                <div className={cx('desktop')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={260}
                        speed={1.5}
                        title={null}
                        uniqueKey="TripCardLoaderMobile"
                        width={720}
                    >
                        <rect height="20" rx="8" width="200" x="24" y="24" />
                        <rect height="32" rx="8" width="200" x="24" y="84" />
                        <rect height="24" rx="8" width="200" x="24" y="124" />
                        <rect height="20" rx="8" width="20" x="676" y="24" />

                        <rect height="48" rx="8" width="220" x="24" y="192" />
                        <rect height="48" rx="8" width="224" x="416" y="192" />
                        <rect height="48" rx="8" width="48" x="648" y="192" />
                    </ContentLoaderBase>
                </div>
            </Depth>
        </div>
    );

    const blockLoader = (
        <div className={cx('card')}>
            <Depth depth="z50">
                <div className={cx('mobile')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={60}
                        speed={1.5}
                        title={null}
                        uniqueKey="TripBlockLoaderMobile"
                        width={344}
                    >
                        <rect height="28" rx="8" width="203" x="16" y="16" />
                        <rect height="20" rx="8" width="20" x="308" y="20" />
                    </ContentLoaderBase>
                </div>
                <div className={cx('desktop')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={76}
                        speed={1.5}
                        title={null}
                        uniqueKey="TripBlockLoader"
                        width={720}
                    >
                        <rect height="28" rx="8" width="200" x="24" y="24" />
                        <rect height="20" rx="8" width="20" x="676" y="28" />
                    </ContentLoaderBase>
                </div>
            </Depth>
        </div>
    );

    return (
        <div className={cx('root', 'trip-page')}>
            <div className={cx('skeleton')}>
                <>
                    <div className={cx('trip-back')}>
                        <div className={cx('mobile')}>
                            <ContentLoaderBase
                                backgroundColor="#ECEFF5"
                                foregroundColor="#FFFFFF"
                                height={56}
                                speed={1.5}
                                title={null}
                                uniqueKey="TripBackMobile"
                                width={360}
                            >
                                <rect
                                    height="20"
                                    rx="8"
                                    width="20"
                                    x="16"
                                    y="18"
                                />
                                <rect
                                    height="20"
                                    rx="8"
                                    width="296"
                                    x="48"
                                    y="18"
                                />
                            </ContentLoaderBase>
                        </div>
                    </div>

                    <div className={cx('mobile')}>
                        <ContentLoaderBase
                            backgroundColor="#FFFFFF"
                            foregroundColor="#ECEFF5"
                            height={160}
                            speed={1.5}
                            title={null}
                            uniqueKey="TripSwitcherLoader"
                            width={360}
                        >
                            <rect
                                height="16"
                                rx="8"
                                width="100"
                                x="16"
                                y="16"
                            />
                            <rect
                                height="56"
                                rx="8"
                                width="164"
                                x="16"
                                y="48"
                            />
                            <rect
                                height="24"
                                rx="8"
                                width="164"
                                x="16"
                                y="112"
                            />
                            <rect
                                height="20"
                                rx="8"
                                width="20"
                                x="324"
                                y="16"
                            />
                        </ContentLoaderBase>
                    </div>
                    {cardLoader}

                    {blockLoader}
                    {blockLoader}
                    {blockLoader}
                </>
            </div>
        </div>
    );
};

export default TripLoader;
