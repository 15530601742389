const Sentry = require('@sentry/node');
const { RewriteFrames } = require('@sentry/integrations');

const { sentryEnabled, stage } = require('../stages');

const { skipEvent } = require('./skipEvent');

const ignorePaths = [
    '@s7-chat/s7-chat-widget',
    'addthis_widget',
    'api.flocktory.com',
];

module.exports = function initSentry() {
    const integrations = [];

    if (
        process.env.NEXT_IS_SERVER === 'true' &&
        process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
        // For Node.js, rewrite Error.stack to use relative paths, so that source
        // maps starting with ~/_next map to files in Error.stack with path
        // app:///_next
        integrations.push(
            new RewriteFrames({
                iteratee: frame => {
                    frame.filename = frame.filename.replace(
                        process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
                        'app:///',
                    );
                    frame.filename = frame.filename.replace('.next', '_next');

                    return frame;
                },
            }),
        );
    }

    Sentry.init({
        beforeSend(event) {
            return skipEvent(event, ignorePaths) ? null : event;
        },
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        enabled: sentryEnabled,
        environment: stage,
        ignoreErrors: [
            // Ошибки от @s7-chat/s7-chat-widget, на работу приложения не влияет
            // на данный момент ошибки не поправить, chat-widget планируют переписывать
            'OperationError: The operation failed for an operation-specific reason',
            "Cannot read properties of null (reading 'scrollTop')",
            "Cannot read properties of null (reading 'send')",

            // Ошибка на стороне addthis widget и мы не можем на это повлиять.
            "Cannot read property 'flushed' of undefined",

            // Ошибки от api.flocktory.com, https://s7airlines.atlassian.net/browse/CSSSRS7-3047
            'JSONP request to https://api.flocktory.com/u_shaman/setup-api.js',
            "Cannot read property 'permission' of undefined",
            'Promise timed out after 1000 milliseconds',
            "Can't find variable: $",
            'Failed to fetch',
            'NetworkError when attempting to fetch resource.',
            'Load failed',
            'Resource blocked by content blocker',

            // Ошибка oauth "idpiframe_initialization_failed"
            'Non-Error exception captured with keys: details, error',

            // Ошибка oauth "popup_closed_by_user"
            'Non-Error exception captured with keys: error',

            // Спам, прилетает с Yandex Browser iphone/ipad
            "undefined is not an object (evaluating '__ybro.readability.runDetection')",
            'The string did not match the expected pattern',
            "undefined is not an object (evaluating 'window.webkit.messageHandlers[a].postMessage')",

            // Спам, прилетает с Chrome Mobile WebView для Vivo и Generic смартфонов
            'processRandomSelector is not defined',

            // Same-origin policy
            'Blocked a frame with origin "https://myprofile.s7.ru" from accessing a cross-origin frame',

            // ошибка при модифицировании DOM, расширениями или Google Translate
            // подробности в задаче https://s7airlines.atlassian.net/browse/CSSSRS7-3464
            "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node",

            // Firefox ошибка
            // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Errors/Illegal_character
            'illegal character U+009E',

            // ошибка Google Maps
            'Could not load "stats".',

            // Когда страница загружается, кнопка обновления меняется на крестообразную кнопку,
            // если во время загрузки страницы выполняется какой-либо запрос API,
            // и пользователь нажимает эту крестообразную кнопку, то браузер выдает ошибку
            // ниже перечислены эти ошбики в зависимости от браузера
            // Chrome и Chrome-based браузеры (Edge, Yandex Browser)
            'Failed to fetch',
            // Firefox
            'NetworkError when attempting to fetch resource.',
            // Safari
            'cancelled',
            'отменено',
        ],
        integrations,
        release: process.env.SENTRY_RELEASE,
    });

    return Sentry;
};
