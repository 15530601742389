import { COOKIES } from 'consts';
import { cookieService } from 'services';

export const isInApp = ctx => {
    const {
        query: { iframe, mobile },
        req,
    } = ctx;

    const inAppCookie = req
        ? req.cookies.inApp
        : cookieService.get(COOKIES.IN_APP);
    // если mobile указывается в url без значения, например myprofile.s7.ru/?mobile
    // значение mobile в таком случае будет равно пустой строке ""
    // поэтому достаточно проверить что mobile не undefined
    const inAppQuery = iframe === 'true' || mobile !== undefined;

    return inAppQuery || inAppCookie === 'true';
};
