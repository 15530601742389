import React from 'react';
import { node, oneOf, string } from 'prop-types';

import cx from './Hidden.sss';

const Hidden = ({ on, className, children }) => (
    <div className={cx(className, { [`on_${on}`]: on })}>{children}</div>
);

Hidden.propTypes = {
    children: node,
    className: string,
    on: oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Hidden;
